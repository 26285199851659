@use "../../styles/abstracts" as *;

.user_rowr {
  display: flex;
  flex: 1;
  align-items: center;
  gap: $container_margin;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $container_margin;

    &__name {
      display: flex;
      align-items: center;
      font-size: 16px;
      span {
        margin-left: 10px;
      }
    }
    &__contact {
      display: flex;
      flex-direction: row;
      gap: $container_margin * 3;
      color: grey;

      &__id,
      &__phone,
      &__email {
        display: flex;
        align-items: center;
        font-size: 14px;
        span {
          font-weight: 400;
          color: black;
        }
      }

      &__phone,
      &__email {
        display: flex;
        align-items: center;
        span {
          &:not(:first-child) {
            padding-right: $container_margin_2x;
            padding-left: $container_margin;
          }
        }
      }
    }
  }
}
