@use "../../../../styles/abstracts/" as *;

.custom_options_selector {
  display: flex;
  padding: $content_padding;
  &__option {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    padding: $content_padding;
    margin: 0 $content_margin;
    color: #000;
    position: relative;
    cursor: pointer;
    // &:hover {
    //   &::after {
    //     content: "";
    //     width: 6px;
    //     height: 6px;
    //     border-radius: $border_radius_round;
    //     background-color: #ddd;
    //   }
    // }
  }
  &__active {
    color: $color_accent;
    &::after {
      content: "";
      position: absolute;
      top: 30px;
      width: 6px;
      height: 6px;
      border-radius: $border_radius_round;
      background-color: $color_accent;
    }
  }
}
