@use "../../../styles/abstracts" as *;

.layout_with_header_container {
  display: flex;
  flex-direction: column;
  &__content {
    margin-top: 70px;
    min-height: calc(100vh - 70px);
    padding: 0 $container_padding_2x $container_padding_2x;
    &__header {
      display: flex;
      position: relative;
      padding: $container_padding 0;
      border-bottom: 1px solid $border;
    }
    &__body {
      padding: $container_padding 0;
    }
  }
}
@media (max-width: 375px) {
  .layout_with_header_container {
    &__content {
      padding: 0 $content_padding $container_padding;
    }
  }
}
