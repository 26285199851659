$color_primary: #1b3d63;
$color_primary_hover: #0c335f;
$color_primary_active: #002c5e;
$color_secondary: #fff;
$color_accent: #f44336;

// Buttons and labels
$success: #4caf50;
$success_hover: #2e7d32;
$success_active: #1b5e20;
$regular: #cccccc;
$disabled: #dddddd;
$danger: #f44336;
$danger_hover: #c62828;
$danger_active: #b71c1c;

$modal_text_field: #888;

$info: #2196f3;
$warning: #ff9800;

$border: #c4c4c4;
$border_2: #dcdcdc;

$content_border: #eee;
$content_hover_background: #eee;
