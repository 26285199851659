@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins";
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;
}

p {
  margin: 0;
}

.section_title {
  font-size: 18px;
  font-weight: 500;
  color: #666;
  margin-bottom: 10px;
}

@layer utilities {
  .active-dot {
    content: "";
    background-color: #fff;
    width: 6px;
    height: 6px;
    bottom: -8px;
    position: absolute;
    border-radius: 100%;
  }
}
