.input_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0 0 0;
  align-items: center;
  border: none;
  border-bottom: 1px solid #aaa;
  transition: 0.2s border ease-in-out;
  position: relative;
  &.show_error {
    border-bottom: 1px solid #f44336;
  }
  &__input {
    padding: 15px;
    // margin: 0 10px;
    // padding-left: 0;
    font-size: 20px;
    border: none;
    outline: none;
    width: inherit;
    flex: 1;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    &.largeField {
      min-height: 100px;
      max-height: 120px;
      resize: none;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  &__error_message {
    color: #f44336;
    position: absolute;
    left: 15px;
    bottom: -18px;
    font-size: 12px;
    font-style: italic;
    z-index: 1;
  }
}
