.modal {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 0;
  &::backdrop {
    background-color: rgba(#000, 0.5);
  }
  .modal_container {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    &.small {
      width: 550px;
    }
    &.medium {
      width: 680px;
    }
    &.large {
      width: 830px;
    }
    &.xlarge {
      width: 90%;
    }
    .modal_header_container {
      display: flex;
      align-items: center;
      padding: 15px 30px;
      .modal_header_title {
        display: flex;
        align-items: center;
        flex: 1;
        font-size: 24px;
        margin: 0;
      }
      .modal_header_close_button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        // padding: 0 10px;
        color: #555;
        cursor: pointer;
        &:hover {
          color: #000000;
        }
      }
      &.header-border {
        border-bottom: 1px solid #ddd;
      }
    }
    .modal_body_container {
      padding: 10px 30px;
      max-height: 70vh;
      overflow-y: auto;
    }
    .modal_footer_container {
      padding: 15px 30px;
      border-top: 1px solid #ddd;
      .modal_footer_text {
        font-size: 18px;
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .modal {
    .modal_container {
      &.small {
        width: 65%;
      }
      &.medium {
        width: 85%;
      }
      &.large {
        width: 95%;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .modal {
    .modal_container {
      &.small {
        width: 75%;
      }
      &.medium {
        width: 85%;
      }
      &.large {
        width: 95%;
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .modal {
    .modal_container {
      padding: 5px 10px;
      .modal_header_container {
        padding: 5px;
        .modal_header_title {
          font-size: 20px;
        }
      }
      .modal_body_container {
        padding: 5px;
      }
      &.small,
      &.medium,
      &.large {
        width: 95%;
      }
    }
  }
}
