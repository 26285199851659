@use "../../styles/abstracts/dimentions" as *;
@use "../../styles/abstracts/colors" as *;

.pagination_container {
  display: flex;
  align-items: center;
  border: 1px solid $border;
  padding: 0 $container_padding 0 0;
  border-radius: $border_radius_3x;
  gap: 5px;

  .pages_limit_dropdown_container {
    display: flex;
    align-items: center;
    padding: 0 0 0 $container_padding;
    border: none;
    border-radius: 0;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    img {
      width: 14px;
      margin-right: $content_margin;
    }
    &__title {
      padding: $container_padding;
      flex: 1;
      font-size: 12px;
      white-space: nowrap;
    }

    &__values {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      border: 1px solid $border;
      border-radius: $border_radius_3x;
      background: #fff;
      display: none;
      &__value {
        font-size: 12px;
        padding: $container_padding $container_padding_2x;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $content_border;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background: $content_hover_background;
          &:first-child {
            border-radius: $border_radius_3x $border_radius_3x 0 0;
          }
          &:last-child {
            border-radius: 0 0 $border_radius_3x $border_radius_3x;
          }
        }

        &.active {
          color: $color_accent;

          &::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            left: 7px;
            border-radius: $border_radius_round;
            background-color: $color_accent;
          }
        }
      }

      &.open_dropdown {
        display: flex;
        flex-direction: column;
        animation: fade_in 0.2s linear forwards;
      }
    }

    &::after {
      content: "";
      width: 1px;
      height: 32px;
      background: $border;
      margin-left: $content_margin_2x;
    }
  }

  &__pages_number_container {
    display: flex;
    align-items: center;
    padding: $container_padding + 5 $container_padding;
    gap: 5px;
    img {
      width: 14px;
      cursor: pointer;
    }
    &__pages {
      display: flex;
      align-items: center;
      padding: 0 $content_padding;
      &__page {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        padding: 0 $content_padding;
        margin: 0 $content_margin;
        color: #888;
        position: relative;
        cursor: pointer;
      }
      &__active {
        color: $color_accent;
        &:after {
          content: "";
          position: absolute;
          bottom: -8px;
          //   left: 8px;
          margin-top: $content_margin;
          width: 6px;
          height: 6px;
          border-radius: $border_radius_round;
          background-color: $color_accent;
        }
      }
    }
  }
}

@keyframes fade_in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .pagination_container {
    .pages_limit_dropdown_container {
      img {
        width: 15px;
      }
      &__title {
        padding: $container_padding + 5 $container_padding;
        font-size: 15px;
      }
      &__values {
        top: 60px;
        &__value {
          font-size: 14px;
          padding: $container_padding + 5 $container_padding_2x;
        }
      }
    }

    &__pages_number_container {
      padding: $container_padding + 5 $container_padding;
      img {
        width: 15px;
      }
      &__pages {
        &__page {
          font-size: 15px;
        }
      }
    }
  }
}
