@use "../../../styles/abstracts/dimentions" as *;

.layout_container {
  display: flex;
  flex-direction: column;
  &__content {
    margin-top: 70px;
    min-height: calc(100vh - 70px);
    padding: $container_padding_2x $container_padding_2x $container_padding_2x;
  }
}
