@use "../../../styles/abstracts/dimentions" as *;
@use "../../../styles/abstracts/colors" as *;

.loader_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  width: 100%;

  p {
    font-size: 18px;
    color: $color_primary;
    padding: $container_padding;
  }
  &__spinner {
    width: 20px;
    height: 20px;
    border: 3px solid white;
    border-right: 3px solid $color_primary;
    border-radius: 50%;
    margin: 0 $content_margin_2x;
    animation: spinner 1.3s linear infinite;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(540deg);
  }

  100% {
    transform: rotate(720deg);
  }
}
