@use "../../styles/abstracts" as *;

.users_page_header_container {
  flex: 1;
  .dropdown_with_icon_container {
    &__values {
      width: 240px;
    }
  }
  .search_input_with_filter_container__search_dropdown_container {
    min-width: 180px;
    &__values {
      min-width: 180px;
    }
  }
}

.users_container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: $container_margin;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.2s linear;
    padding: $container_padding;
    border-radius: $border_radius_3x;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
      background-color: $content_border;
    }

    &:active {
      cursor: pointer;
      background-color: rgba(#000, 0.1);
    }

    &__right {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      color: gray;
      padding: $content_padding_2x;

      .user_account_status {
        display: flex;
        justify-content: flex-end;
      }

      .user_join_date {
        &__value {
          padding-left: $content_padding_2x;
          font-weight: 400;
          color: black;
        }
      }
    }
  }
}

.user_detail_container {
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  gap: $container_margin_2x;

  &__left {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__details {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      place-items: center;
      gap: $container_margin;

      .user_detail_label {
        width: 100%;
        font-size: 14px;
        text-align: end;
        color: #555;
        font-weight: 200;
        padding-right: $content_padding;
        margin-right: $container_margin;
      }

      .user_detail_value {
        font-size: 16px;
        width: 100%;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    justify-content: space-evenly;
    gap: $container_margin_2x;

    span {
      color: grey;
      text-align: center;
      padding: 0;
      margin-top: 0;
    }

    &__user_status {
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      border: 1px solid $border_2;
      border-radius: 10px;
      padding: $content_padding_2x $container_padding_2x;
      align-items: center;
    }

    &__user_type {
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      border: 1px solid $border_2;
      border-radius: 10px;
      padding: $content_padding_2x $container_padding_2x;
      align-items: center;
    }

    &__joindate {
      &__label {
        text-align: end;
        color: grey;
        font-weight: 400;
      }

      &__value {
        text-align: end;
        font-size: large;
        font-weight: 500;
      }
    }
  }
}

.filter_modal_container {
  display: flex;
  flex-direction: column;
  gap: $container_margin_2x;

  &:first-child {
    margin-top: $container_margin_2x;
  }

  &__item {
    display: flex;
    align-items: center;

    &__title {
      color: $modal_text_field;
    }

    &__value {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: $container_margin_2x 0;
    gap: $container_margin;
  }
}

@media (max-width: 768px) {
  .users_container {
    &__item {
      flex-direction: column;
      align-items: flex-start;

      &__right {
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-left: 36px;
        gap: $container_margin;

        .user_account_status {
          justify-content: flex-start;
        }

        .user_join_date {
          font-size: 14px;
        }
      }
    }
  }
}
