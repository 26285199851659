$container_margin: 10px;
$container_margin_2x: 20px;
$content_margin: 5px;
$content_margin_2x: 7px;

$container_padding: 10px;
$container_padding_2x: 20px;
$content_padding: 5px;
$content_padding_2x: 7px;

$header_links_margin: 20px;
$header_links_padding: 20px;

$border_radius_1x: 3px;
$border_radius_2x: 5px;
$border_radius_3x: 7px;
$border_radius_4x: 10px;
$border_radius_round: 50%;
