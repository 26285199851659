@use "../../../../styles/abstracts/" as *;

.confirm_password_container {
  display: flex;
  flex-direction: column;

  .error_text {
    padding: $container_padding 0 $container_padding_2x 0;
  }

  .input_container {
    margin-top: 0;
  }

  .button_container {
    margin-top: $container_padding_2x;
    align-self: flex-end;
  }
}
