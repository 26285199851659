@use "../../styles/abstracts/dimentions" as *;
@use "../../styles/abstracts/colors" as *;

.header_container {
  display: flex;
  height: 60px;
  align-items: center;
  gap: $container_margin;

  &__back_icon {
    width: 52px;
    padding: $container_padding;
    transition: 0.2s linear;
    border-radius: $border_radius_round;
    &:hover {
      background-color: $content_border;
      cursor: pointer;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    padding: $container_padding;
    &__separator {
      color: #aaa;
      font-size: 32px;
      font-weight: 200;
      margin: 0 $container_margin_2x;
    }
    &__id {
      color: #888;
      padding-right: $container_padding;
    }
  }
  &__content {
    display: flex;
    // align-items: center;
    gap: $container_margin;
    justify-content: flex-end;
    flex: 1;
    padding: $container_padding;
  }
}
