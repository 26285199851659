@use "../../../styles/abstracts/colors" as *;

.error_text {
  color: $danger;
  font-size: 16px;
  animation: shake 0.4s linear;
}

@keyframes shake {
  0% {
    transform: translateX(0px);
  }
  10% {
    transform: translateX(-10px);
  }
  20% {
    transform: translateX(0px);
  }
  30% {
    transform: translateX(10px);
  }
  40% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(0px);
  }
  70% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(0px);
  }
  90% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0px);
  }
}
