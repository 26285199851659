@use "../../../styles/abstracts/colors" as *;

$labels: (
  "info": $info,
  "success": $success,
  "danger": $danger,
  "warning": $warning,
);

@each $key, $val in $labels {
  .label-#{$key} {
    background-color: $val;
    color: white;
    border-radius: 20px;
    padding: 2px 8px;
    font-size: 8px;
    white-space: nowrap;
  }
}

@media (min-width: 768px) {
  @each $key, $val in $labels {
    .label-#{$key} {
      padding: 3px 10px;
      font-size: 12px;
    }
  }
}

@media (max-width: 375px) {
  @each $key, $val in $labels {
    .label-#{$key} {
      white-space: normal;
      text-align: center;
    }
  }
}
