@use "../../../../styles/abstracts/dimentions" as *;
@use "../../../../styles/abstracts/colors" as *;

.icon_button_container {
  display: flex;
  align-items: center;
  width: 36px;
  min-height: 36px;
  max-height: 36px;
  min-width: 36px;
  justify-content: center;
  padding: 0 $content_padding_2x;
  border: 1px solid $border;
  border-radius: $border_radius_3x;
  transition: 0.2s linear;
  z-index: 1;
  img {
    width: 32px;
    margin: 0;
    transition: 0.2s linear;
  }
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
    cursor: pointer;

    img {
      scale: 1.3;
    }
  }

  &:active {
    background-color: transparent;
    img {
      scale: 1;
    }
  }
}

@media (min-width: 768px) {
  .icon_button_container {
    width: 55px;
    min-height: 55px;
    max-height: 55px;
    min-width: 55px;
    padding: 0 $container_padding;
    img {
      width: 24px;
    }
  }
}
