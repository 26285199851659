@use "../../styles/abstracts/" as *;

.login_container {
  background-color: $color_primary;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  //   padding: $container_padding_2x * 3;
  &__title {
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    padding: $container_padding_2x;
  }
  &__form {
    border-radius: $border_radius_3x;
    background-color: $color_secondary;
    padding: $container_padding_2x $container_padding * 2;
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    &__title {
      padding: $container_padding $container_padding $container_padding_2x;
      font-size: 24px;
      font-weight: bold;
    }
    .error_text {
      margin-bottom: $container_margin;
      text-align: center;
    }
    &__container {
      align-self: flex-start;
      width: 100%;
      .input_container {
        margin-top: 20px;
      }
      div {
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .button_container {
      align-self: flex-end;
      margin: $container_margin * 3 0 $container_margin;
    }
  }
}

@media (max-width: 768px) {
  .login_container {
    &__title {
      font-size: 32px;
    }
    &__form {
      width: 60%;
      &__title {
        font-size: 26px;
      }
      &__container {
        .input_container {
          input {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .login_container {
    &__title {
      font-size: 28px;
    }
    &__form {
      padding: $container_padding $container_padding;
      width: 95%;
      &__title {
        font-size: 22px;
      }
      &__container {
        .input_container {
          input {
            font-size: 14px;
          }
        }
      }
    }
  }
}

// .light {
//   &.login_container {
//     background-color: #fff;
//     .login_container__text {
//       color: red;
//     }
//   }
// }

// .dark {
//   &.login_container {
//     background-color: #000;
//     .login_container__text {
//       color: white;
//     }
//   }
// }
