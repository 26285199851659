@use "../../../../styles/abstracts/dimentions" as *;
@use "../../../../styles/abstracts/colors" as *;

.search_input_with_filter_container {
  display: flex;
  align-items: center;
  border: 1px solid $border;
  padding: 0;
  border-radius: $border_radius_3x;

  &__search_dropdown_container {
    display: flex;
    align-items: center;
    padding: 0 0 0 $container_padding;
    border: none;
    border-radius: 0;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    img {
      width: 14px;
      margin-right: $content_margin;
    }
    &__title {
      padding: $container_padding;
      flex: 1;
      font-size: 12px;
      white-space: nowrap;
    }

    &__values {
      position: absolute;
      top: 50px;
      left: 0px;
      border: 1px solid $border;
      border-radius: $border_radius_3x;
      background: #fff;
      display: none;
      &__value {
        font-size: 12px;
        white-space: nowrap;
        padding: $container_padding $container_padding_2x;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $content_border;
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background: $content_hover_background;
          &:first-child {
            border-radius: $border_radius_3x $border_radius_3x 0 0;
          }
          &:last-child {
            border-radius: 0 0 $border_radius_3x $border_radius_3x;
          }
        }

        &.active {
          color: $color_accent;

          &::before {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            left: 7px;
            border-radius: $border_radius_round;
            background-color: $color_accent;
          }
        }
      }

      &.open_dropdown {
        display: flex;
        flex-direction: column;
        animation: fade_in 0.2s linear forwards;
      }
    }

    &::after {
      content: "";
      width: 1px;
      height: 32px;
      background: $border;
      margin-left: $content_margin_2x;
    }
  }

  .search_input_icon {
    width: 14px;
    margin-left: $content_margin;
  }
  &__input {
    flex: 1;
    font-size: 12px;
    padding: $container_padding + 3 $container_padding;
    border: none;
    outline: none;
    margin-right: $content_margin;
    box-sizing: border-box;
  }
}
@keyframes fade_in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .search_input_with_filter_container {
    &__search_dropdown_container {
      cursor: pointer;
      img {
        width: 15px;
      }
      &__title {
        padding: $container_padding + 5 $container_padding;
        font-size: 15px;
      }
      &__values {
        top: 60px;
        &__value {
          font-size: 14px;
        }
      }
    }
    .search_input_icon {
      width: 18px;
      margin-left: $content_margin + $container_margin;
    }
    &__input {
      font-size: 15px;
      min-width: 200px;
    }
  }
}

@media (min-width: 1440px) {
  .search_input_with_filter_container {
    &__input {
      font-size: 15px;
      min-width: 300px;
    }
  }
}
