@use "../../../styles/abstracts/" as *;

.button_container {
  display: flex;
  align-items: center;
  gap: $content_margin_2x;
  padding: $container_margin;
  border-radius: $border_radius_3x;
  transition: 0.1s linear;
  cursor: pointer;

  &__loader {
    width: 12px;
    height: 12px;
    border: 1px solid $color_primary;
    border-right: 2px solid white;
    border-radius: 50%;
    animation: button_loader_spinner 1s linear infinite;
  }

  &__button {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    background: transparent;
    border: none;
    border: none;
    cursor: pointer;
  }
  &.primary {
    background: $color_primary;
    border: 1px solid $color_primary;
    &:hover {
      background: $color_primary_hover;
    }
    &:active {
      background: $color_primary_active;
    }
  }
  &.success {
    background: $success;
    border: 1px solid $success;
    .button_container__loader {
      border-color: $success;
      border-right-color: white;
    }
    &:hover {
      background: $success_hover;
    }
    &:active {
      background: $success_active;
    }
  }
  &.danger {
    background: $danger;
    border: 1px solid $danger;
    .button_container__loader {
      border-color: $danger;
      border-right-color: white;
    }
    &:hover {
      background: $danger_hover;
    }
    &:active {
      background: $danger_active;
    }
  }
  &.disabled {
    opacity: 0.8;
    &.primary {
      &:hover {
        background: $color_primary;
      }
      &:active {
        background: $color_primary;
      }
    }
    &.success {
      &:hover {
        background: $success;
      }
      &:active {
        background: $success;
      }
    }
    &.danger {
      &:hover {
        background: $danger;
      }
      &:active {
        background: $danger;
      }
    }
  }

  @keyframes button_loader_spinner {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(180deg);
    }

    50% {
      transform: rotate(540deg);
    }

    100% {
      transform: rotate(720deg);
    }
  }
}

@media (max-width: 425px) {
  .button_container {
    gap: $content_margin;
    padding: $content_margin;

    &__loader {
      width: 8px;
      height: 8px;
      border: 2px solid $color_primary;
      border-right: 2px solid white;
    }
    &__button {
      font-size: 10px;
    }
  }
}

@media (min-width: 768px) {
  .button_container {
    padding: $content_padding_2x * 2;
    gap: $container_margin;
    &__loader {
      width: 20px;
      height: 20px;
      border: 3px solid $color_primary;
      border-right: 3px solid white;
    }
    &__button {
      font-size: 16px;
    }
  }
}
