@use "../../styles/abstracts" as *;

.orders_page_header_container {
  flex: 1;
  .dropdown_with_icon_container__values {
    min-width: 235px;
    max-width: 235px;
  }
}

.filter_modal_container {
  display: flex;
  flex-direction: column;
  gap: $container_margin_2x;
  &:first-child {
    margin-top: $container_margin_2x;
  }
  &__item {
    display: flex;
    align-items: center;
    &__title {
      color: $modal_text_field;
    }
    &__value {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: $container_margin_2x 0;
    gap: $container_margin;
  }
}

// .orders_container {
//   overflow-y: auto;
//   height: 70vh;
//   &__item {
//     display: flex;
//     align-items: center;
//     gap: $container_margin;
//     padding-right: $container_padding;
//     transition: 0.2s linear;

//     &:hover {
//       cursor: pointer;
//       background-color: rgba($color: #000000, $alpha: 0.05);
//     }
//     &__left {
//       flex: 1;
//       padding: $container_padding_2x;
//       &__order_id {
//         display: flex;
//         padding: 0 0 $container_padding 0;
//         &__title {
//           display: flex;
//           align-items: center;
//           font-weight: 500;
//           color: #888;
//           &__id {
//             // display: flex;
//             // align-items: center;
//             color: #000;
//             padding: 0 $container_padding;
//           }
//         }
//       }
//     }
//     &__right {
//       display: flex;
//       width: 60px;
//       height: 60px;
//     }
//   }
// }

.order_data_container {
  display: flex;
  flex-direction: column;
  gap: $container_margin_2x * 2;
  // padding: $container_padding_2x;
  overflow-y: auto;
  height: 100%;

  &__user_related {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $container_margin_2x;
    &__assigned_to {
      .section_title {
        display: flex;
        align-items: center;
        gap: $container_margin;
      }
    }
    &__divider {
      width: 1px;
      height: 60px;
      background-color: $border;
      margin: 0 $container_margin_2x;
    }
  }

  &__items {
    &__header {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 200;
      color: #666;
    }
    &__content {
      display: flex;
      flex-direction: column;
      // margin: $container_margin -10px;
      overflow-y: auto;
      &__row {
        display: flex;
        align-items: flex-start;
        padding: $container_padding + 5 0;
        border-bottom: 1px solid $content_border;
        transition: 0.2s linear;
        font-size: 14px;
        &:hover {
          background-color: $content_border;
          cursor: pointer;
        }
      }
      :last-child {
        border-bottom: none;
      }
    }
    .col_1 {
      flex: 20%;
    }
    .col_2,
    .col_3 {
      flex: 10%;
    }

    .col_4 {
      flex: 10%;
    }

    .col_5,
    .col_6 {
      flex: 15%;
    }
    .col_7 {
      flex: 20%;
    }
  }
}

.assign_modal_container {
  display: flex;
  flex-direction: column;
  height: 500px;
  &__search_section {
    display: none;
    flex: 1;
    height: 100%;
    overflow: hidden;
    &__content {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      gap: $container_margin;
      &__no_users {
        color: #555;
        font-weight: 00;
        font-size: 14px;
        margin-top: $container_margin_2x;
      }
      &__users {
        margin-top: $container_padding;
        padding: $container_padding;
        border-radius: $border_radius_3x;
        transition: all 0.1s linear;
        cursor: pointer;
        &:first-child {
          margin-top: $container_margin_2x;
        }
        &:hover {
          background: $content_border;
        }
        &:active {
          background: rgba(#000, 0.1);
        }
        &.selected {
          background: rgba(#000, 0.1);
        }
      }
    }
  }

  &__confirm_section {
    display: none;
    flex: 1;
    // border: 1px solid black;
    &__feedback {
      margin-top: $container_margin_2x;
    }
  }

  .active_section {
    display: flex;
    flex-direction: column;
  }

  .search_input_with_filter_container {
    margin: $container_margin_2x 0 0 0;
  }
  .search_input_with_filter_container__search_dropdown_container,
  .search_input_with_filter_container__search_dropdown_container__values {
    width: 180px;
  }

  &:first-child {
    margin-top: $container_margin_2x;
  }

  &__actions {
    display: flex;
    margin: $container_margin_2x 0 $container_margin;
    justify-content: flex-end;
    gap: $container_margin;
  }
}

.assign_button_container {
  display: flex;
  align-items: center;
  padding: $container_padding_2x;
  border-radius: $border_radius_2x;
  gap: $container_margin;
  cursor: pointer;
  &.unassigned {
    background-color: $color_primary;
  }
  &.reassign {
    background-color: $color_accent;
  }
  &__icon {
    width: 24px;
  }
  &__title {
    color: #fff;
  }
}
