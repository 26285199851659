@use "../../styles/abstracts" as *;

.home_container {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__text {
    font-size: 24px;
    font-weight: bold;
  }
  &__action {
    padding: 20px 30px;
    border-radius: 5px;
    font-style: italic;
    cursor: pointer;
  }
}
.light {
  &.home_container {
    background-color: #fff;
    .home_container__text {
      color: red;
    }
    .home_container__action {
      background-color: #eee;
      border: 10px solid #ddd;
      color: #000;
    }
  }
}

.dark {
  &.home_container {
    background-color: #111;
    .home_container__text {
      color: #fff;
    }
    .home_container__action {
      background-color: #333;
      border: 10px solid blue;
      color: #fff;
    }
  }
}
