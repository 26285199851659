@use "../../../styles/abstracts/" as *;

.nav_logo {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  transition: 0.1s linear;
  padding: $content_padding $container_padding;
  z-index: 2;
  &__top {
    display: flex;
    justify-content: space-between;
    span {
      color: $color_accent;
    }
    display: flex;
  }
  &__bottom {
    display: block;
    letter-spacing: 2px;
    color: #fff;
  }

  &.small {
    font-size: 18px;
  }
  &.medium {
    font-size: 24px;
  }
  &.large {
    font-size: 32px;
  }

  &.white_background {
    .nav_logo {
      &__bottom {
        color: $color_primary;
      }
    }
  }
}
