@use "../../../../styles/abstracts/dimentions" as *;
@use "../../../../styles/abstracts/colors" as *;

.dropdown_with_icon_container {
  display: flex;
  align-items: center;
  width: 36px;
  min-width: 36px;
  min-height: 36px;
  max-height: 36px;
  justify-content: center;
  padding: 0 $content_padding_2x;
  border: 1px solid $border;
  border-radius: $border_radius_3x;
  position: relative;
  transition: 0.2s linear;
  z-index: 1;
  cursor: pointer;
  img {
    width: 32px;
    margin: 0;
    transition: 0.2s linear;
  }

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
    cursor: pointer;

    img {
      scale: 1.3;
    }
  }

  &:active {
    background-color: transparent;
    img {
      scale: 1;
    }
  }

  &__values {
    position: absolute;
    top: 60px;
    left: 0;
    min-width: 200px;
    // max-width: 200px;
    border: 1px solid $border;
    border-radius: $border_radius_3x;
    background: #fff;
    display: none;
    z-index: 1;
    &__value {
      font-size: 14px;
      // padding: $container_padding $container_padding_2x;
      padding: $container_padding + 5 $container_padding_2x;
      display: flex;
      align-items: center;
      cursor: pointer;
      border-bottom: 1px solid $content_border;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background: $content_hover_background;
        &:first-child {
          border-radius: $border_radius_3x $border_radius_3x 0 0;
        }
        &:last-child {
          border-radius: 0 0 $border_radius_3x $border_radius_3x;
        }
      }

      &.active {
        color: $color_accent;

        &::before {
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          left: 7px;
          border-radius: $border_radius_round;
          background-color: $color_accent;
        }
      }
    }
    &.open_dropdown {
      display: flex;
      flex-direction: column;
      animation: fade_in 0.2s linear forwards;
    }
  }
}

@keyframes fade_in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .dropdown_with_icon_container {
    width: 55px;
    min-width: 55px;
    min-height: 55px;
    max-height: 55px;
    padding: 0 $container_padding;
    img {
      width: 36px;
    }
  }
}
